import React from 'react';
import { PlayButton as RobloxPlayButton } from 'Roblox';
import metadataConstants from '../constants/metadataConstants';
import eventStreamConstants, {
  EventStreamMetadata,
  SessionInfoType
} from '../../common/constants/eventStreamConstants';
import usePageReferralTracker from '../../common/hooks/usePageReferralTracker';
import { AttributionType, getAttributionId } from '../../common/utils/attributionUtils';
import { PageContext } from '../../common/types/pageContext';

const { usePlayabilityStatus, DefaultPlayButton } = RobloxPlayButton;

export default function PlayButton(): JSX.Element {
  const { universeId = '', rootPlaceId = '', privateServerLinkCode = '', gameInstanceId = '' } =
    metadataConstants.metadataData() || {};
  const [playabilityStatus, refetchPlayabilityStatus] = usePlayabilityStatus(universeId);
  const attributionId = getAttributionId(AttributionType.GameDetailReferral);
  const referralParams = usePageReferralTracker(
    eventStreamConstants.gameDetailReferral,
    [
      EventStreamMetadata.IsAd,
      EventStreamMetadata.Page,
      EventStreamMetadata.PlaceId,
      EventStreamMetadata.UniverseId,
      EventStreamMetadata.SortPos,
      EventStreamMetadata.Position,
      EventStreamMetadata.GameSetTypeId,
      EventStreamMetadata.GameSetTargetId,
      EventStreamMetadata.FriendId,
      EventStreamMetadata.NumberOfLoadedTiles,
      EventStreamMetadata.AppliedFilters,
      SessionInfoType.DiscoverPageSessionInfo,
      SessionInfoType.HomePageSessionInfo,
      SessionInfoType.GameSearchSessionInfo
    ],
    ['privateServerLinkCode', 'placeId', 'launchData', 'gameInstanceId'],
    {
      [EventStreamMetadata.PlaceId]: rootPlaceId,
      [EventStreamMetadata.UniverseId]: universeId
    }
  );

  return (
    <DefaultPlayButton
      placeId={rootPlaceId}
      rootPlaceId={rootPlaceId}
      universeId={universeId}
      privateServerLinkCode={privateServerLinkCode}
      gameInstanceId={gameInstanceId}
      refetchPlayabilityStatus={refetchPlayabilityStatus}
      playabilityStatus={playabilityStatus}
      eventProperties={{
        [SessionInfoType.DiscoverPageSessionInfo]: referralParams.discoverPageSessionInfo,
        [SessionInfoType.GameSearchSessionInfo]: referralParams.gameSearchSessionInfo,
        [SessionInfoType.HomePageSessionInfo]: referralParams.homePageSessionInfo,
        [EventStreamMetadata.AttributionId]: attributionId,
        [EventStreamMetadata.Page]: referralParams.page,
        [EventStreamMetadata.SortPos]: referralParams.sortPos,
        [EventStreamMetadata.GameSetTypeId]: referralParams.gameSetTypeId,
        [EventStreamMetadata.UniverseId]: universeId,
        [EventStreamMetadata.AppliedFilters]: referralParams.appliedFilters,
        [EventStreamMetadata.PlayContext]: PageContext.GameDetailPage
      }}
    />
  );
}
