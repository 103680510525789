import React from 'react';
import { TranslateFunction } from 'react-utilities';
import { FeatureGameDetails } from '../../common/constants/translationConstants';
import GamesInfoTooltip from '../../common/components/GamesInfoTooltip';

type TGameDescriptionTableGenreDisplayProps = {
  translate: TranslateFunction;
};

const GameDescriptionTableGenreDisplay = ({
  translate
}: TGameDescriptionTableGenreDisplayProps): JSX.Element => {
  return (
    <React.Fragment>
      <span>{translate(FeatureGameDetails.LabelUnavailable)}</span>
      <GamesInfoTooltip tooltipText={translate(FeatureGameDetails.LabelGenreUnderConstruction)} />
    </React.Fragment>
  );
};

export default GameDescriptionTableGenreDisplay;
