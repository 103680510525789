import React from 'react';
import { SocialLinksJumbotron, GameBadgesList } from 'Roblox';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { aboutTabTranslationConfig } from '../translation.config';
import metadataConstants from '../constants/metadataConstants';
import GameDescriptionSection from '../components/GameDescriptionSection';
import RecommendedGamesCarousel from '../../recommendedGames/RecommendedGamesCarousel';
import GameDetailsVirtualEventsSectionRoot from '../../gameDetailsVirtualEvents/GameDetailsVirtualEventsSectionRoot';
import PrivateServerList from '../../../../js/react/serverList/containers/PrivateServerList';
import { Survey } from '../components/SurveyComponent';
import { surveyLocation } from '../../common/constants/configConstants';

const { SocialLinkJumbotronType } = SocialLinksJumbotron;

export const AboutTab = ({ translate }: WithTranslationsProps): JSX.Element => {
  const { universeId = '', placeId = '' } = metadataConstants.metadataData() || {};
  return (
    <div className='game-about-tab-container'>
      <Survey
        locationName={surveyLocation.experienceDetails}
        resourceId={universeId}
        translate={translate}
      />
      <GameDescriptionSection universeId={universeId} placeId={placeId} translate={translate} />
      <SocialLinksJumbotron type={SocialLinkJumbotronType.Game} targetId={universeId} />
      <PrivateServerList showServers={false} translate={undefined} intl={undefined} />
      <GameBadgesList universeId={universeId} />
      <GameDetailsVirtualEventsSectionRoot />
      <div className='container-list games-detail'>
        <RecommendedGamesCarousel />
      </div>
    </div>
  );
};

export default withTranslations(AboutTab, aboutTabTranslationConfig);
