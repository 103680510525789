import React, { useEffect, useState, useCallback, useRef } from 'react';
import '../../../../css/gameDetails/_survey.scss';
import { TranslateFunction } from 'react-utilities';
import { elementVisibilityService, eventStreamService } from 'core-roblox-utilities';
import ResponseOptionButton from './ResponseOptionButton';
import { FeatureGameDetails } from '../../common/constants/translationConstants';
import bedev2Services from '../../common/services/bedev2Services';
import { TSurvey, TSurveyResponseOption } from '../../common/types/bedev2Types';
import { SurveyLeadingIcon } from './SurveyLeadingIcon';
import eventStreamConstants, {
  EventStreamMetadata,
  TSurveyInteraction,
  TSurveyImpression,
  TSurveyInteractionType
} from '../../common/constants/eventStreamConstants';
import { gameDetailsPage } from '../../common/constants/configConstants';

export type TSurveyProps = {
  locationName: string;
  resourceId?: string;
  translate: TranslateFunction;
};

export const Survey = ({ locationName, resourceId, translate }: TSurveyProps): JSX.Element => {
  const [surveyInfo, setSurveyInfo] = useState<TSurvey | null>(null);
  const [isSurveyOpen, setIsSurveyOpen] = useState(true);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const rocapSurveyContainerRef = useRef<HTMLDivElement>(null);
  const disconnectRef = useRef<VoidFunction | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const responseOptions = surveyInfo?.content?.prompt.responseOptions;
  const token = surveyInfo?.meta?.token || '';

  const getSurveyInfo = useCallback(() => {
    bedev2Services
      .getSurvey(locationName, resourceId)
      .then(data => {
        setSurveyInfo(data);
      })
      .catch(() => {
        // Non-blocking, the survey won't be shown and the user can still use the page as normal
      });
  }, [locationName, resourceId]);

  useEffect(() => {
    getSurveyInfo();
  }, [getSurveyInfo]);

  const sendInteractionEvent = (
    interactionType: TSurveyInteractionType,
    selectedTexts?: string[],
    selectedIds?: number[]
  ) => {
    let timeToRespond = -1;
    if (startTimeRef.current) {
      timeToRespond = performance.now() - startTimeRef.current;
    }
    if (surveyInfo && responseOptions) {
      const params: TSurveyInteraction = {
        [EventStreamMetadata.LocationName]: locationName,
        [EventStreamMetadata.ResourceId]: resourceId,
        [EventStreamMetadata.Token]: token,
        [EventStreamMetadata.PromptText]: surveyInfo.content.prompt.promptText,
        [EventStreamMetadata.TimeToRespond]: timeToRespond,
        [EventStreamMetadata.ResponseOptionTexts]: responseOptions.map(option => option.text),
        [EventStreamMetadata.ResponseOptionIds]: responseOptions.map(option => option.id),
        [EventStreamMetadata.SelectedTexts]: selectedTexts,
        [EventStreamMetadata.SelectedIds]: selectedIds,
        [EventStreamMetadata.InteractionType]: interactionType
      };
      const eventStreamParams = eventStreamConstants.surveyInteraction(params);
      eventStreamService.sendEvent(...eventStreamParams);
    }
  };

  const sendImpressionEvent = () => {
    if (surveyInfo && responseOptions) {
      const params: TSurveyImpression = {
        [EventStreamMetadata.LocationName]: locationName,
        [EventStreamMetadata.ResourceId]: resourceId,
        [EventStreamMetadata.Token]: token,
        [EventStreamMetadata.PromptText]: surveyInfo.content.prompt.promptText,
        [EventStreamMetadata.ResponseOptionTexts]: responseOptions.map(option => option.text),
        [EventStreamMetadata.ResponseOptionIds]: responseOptions.map(option => option.id)
      };
      const eventStreamParams = eventStreamConstants.surveyImpression(params);
      eventStreamService.sendEvent(...eventStreamParams);
    }
  };

  const sendSurveyResults = (
    interactionType: TSurveyInteractionType,
    selectedTexts?: string[],
    selectedIds?: number[]
  ) => {
    bedev2Services
      .postSurveyResults(token, locationName, selectedTexts, selectedIds, resourceId)
      .catch(() => {
        // Non-blocking, the survey results won't be recorded but the user can continue as normal
      });
    sendInteractionEvent(interactionType, selectedTexts, selectedIds);
  };

  const closeSurvey = () => {
    setIsSurveyOpen(false);
    if (!surveyCompleted) {
      sendSurveyResults(TSurveyInteractionType.Cancellation);
    }
  };

  const handleResponse = (selectedText: string, selectedId: number) => {
    setShowThankYou(true);
    setSurveyCompleted(true);

    sendSurveyResults(TSurveyInteractionType.Submission, [selectedText], [selectedId]);

    setTimeout(() => {
      setIsSurveyOpen(false);
    }, 2000);
  };

  const getResponseButtons = ({
    responses,
    onClick
  }: {
    responses: TSurveyResponseOption[];
    onClick: (selectedText: string, selectedId: number) => void;
  }): JSX.Element => {
    return (
      <div className='answer-buttons-container'>
        {responses.map(option => (
          <ResponseOptionButton
            key={option.id}
            answerText={option.text}
            onClick={() => onClick(option.text, option.id)}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (rocapSurveyContainerRef?.current) {
      const elementToObserve = rocapSurveyContainerRef.current;

      const onObserve = (visible: boolean) => {
        if (visible) {
          sendImpressionEvent();
          startTimeRef.current = performance.now();

          if (disconnectRef.current) {
            disconnectRef.current();
          }
        }
      };

      disconnectRef.current = elementVisibilityService.observeVisibility(
        {
          element: elementToObserve,
          threshold: gameDetailsPage.surveyImpressionsIntersectionThreshold
        },
        onObserve
      );
    }

    return () => {
      if (disconnectRef.current) {
        disconnectRef.current();
      }
    };
  }, [isSurveyOpen, sendImpressionEvent, rocapSurveyContainerRef]);

  if (!isSurveyOpen) {
    return <React.Fragment />;
  }

  if (!surveyInfo?.content) {
    return <React.Fragment />;
  }

  if (surveyInfo?.content.container.responseType !== 'singleChoice') {
    return <React.Fragment />;
  }

  if (!responseOptions) {
    return <React.Fragment />;
  }

  if (!surveyInfo?.content?.prompt.promptText) {
    return <React.Fragment />;
  }

  if (showThankYou) {
    return (
      <div className='rocap-survey-container'>
        <div className='thank-you-message'>{translate(FeatureGameDetails.LabelThankYou)}</div>
        <div className='close-icon-container thank-you-close'>
          <button onClick={closeSurvey} type='button' className='close-button'>
            <span className='icon-close' />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div ref={rocapSurveyContainerRef} className='rocap-survey-container'>
      <SurveyLeadingIcon iconType={surveyInfo.content.container.iconKey} />
      <div className='question-answer-container'>
        <div className='text-container'>
          <div className='title'>{surveyInfo.content.prompt.promptText}</div>
          <div className='subtitle'>{surveyInfo.content.prompt.subtitleText || ''}</div>
        </div>
        {getResponseButtons({
          responses: responseOptions,
          onClick: handleResponse
        })}
      </div>
      <div className='close-icon-container question-close'>
        <button onClick={closeSurvey} type='button' className='close-button'>
          <span className='icon-close' />
        </button>
      </div>
    </div>
  );
};

export default Survey;
